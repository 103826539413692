import React from "react"

import { use404BackgroundImage } from "../hooks"
import { Layout, SEO } from "../components"
import {
  ButtonPrimary,
  H1,
  Subheading,
  HeroWrapper,
  HeroContentWrapper,
} from "../elements"

const NotFoundPage = () => {
  const { background } = use404BackgroundImage()

  return (
    <Layout>
      <SEO title="404: Nie znaleziono strony"></SEO>
      <HeroWrapper
        fluid={background.childImageSharp.fluid}
        backgroundColor={`var(--color-primary-light)`}
        Tag="section"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center center",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <HeroContentWrapper>
          <H1>Wypłynąłeś na nieznane wody!</H1>
          <Subheading>
            Przepraszamy! Strona, której szukasz przestała istnieć lub wpisałeś
            błędny adres.
          </Subheading>
          <ButtonPrimary to="/">Wróć od strony głównej</ButtonPrimary>
        </HeroContentWrapper>
      </HeroWrapper>
    </Layout>
  )
}

export default NotFoundPage
